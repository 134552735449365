import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import App from './App';
import { darkTheme, lightTheme, sepiaTheme } from './ALMTheme';
import { Helmet } from 'react-helmet';
import UserContextProvider from './context/UserContext';
import Register from './Register';
import { BrowserRouter, MemoryRouter } from 'react-router-dom';



export default function Entry() {
    const [mode, setMode] = React.useState(() => {
        return localStorage.getItem("theme") ?? "light"
      });
      React.useEffect(() => {
        localStorage.setItem('theme', mode);
    },[mode]) 

  

    return (
        <ThemeProvider theme={mode == 'light' ? lightTheme : mode == 'dark' ? darkTheme : sepiaTheme}>
            <Box sx={{ backgroundColor: "primary.main" }}>
                <Helmet>
                    {mode == 'dark' ? <style>{'body { background-color: #282828; }'}</style> : mode == 'light' ? <style>{'body { background-color: #FFF; }'}</style> : <style>{'body { background-color: #f3eed9; }'}</style>}
                </Helmet>
                {
                    <BrowserRouter>
                        <UserContextProvider>
                            <App setMode={setMode} mode={mode} />
                        </UserContextProvider>
                    </BrowserRouter>
                }

            </Box>
        </ThemeProvider>
    );
}
import { Box, CircularProgress, Container, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { searchByWord } from "./Api";
import Divider from '@mui/material/Divider';
var uniqueAyatsArray = []
export default function Indexes({ handleOpenFootNote,setSelectedChapter, setSelectedAyatNumber, navMenuItemClick, searchTerm, setViewType }) {
  const [isLoading, setIsLoading] = useState(false);
 
  
  const [ayats, setAyats] = useState([]);
 
  useEffect(() => {

   if(searchTerm != "")
   {
    setIsLoading(true);
    searchByWord(searchTerm.toString().trim()).then((result) => {
      uniqueAyatsArray = [];
      setAyats(result.ayats);
      result.ayats?.map((ayat, index) => {
        if (uniqueAyatsArray.findIndex(item => item.ayatNumber == ayat.ayatNumber) === -1) {
          uniqueAyatsArray.push(ayat)
        }
      });

      setIsLoading(false);
    }).catch(console.error.bind(console))
  }
}, [searchTerm]);



function mySearchFunc(str, lookup) {
  const regex = new RegExp(`(?:\\S+\\s+){0,3}${lookup}(?:\\s+\\S+){0,3}`, 'giu');


  let m;
  let result = [];
  while ((m = regex.exec(str)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
          regex.lastIndex++;
      }

      if (m)
          //console.log(`Found: ${m}`);
          result.push(m[0]);
  }
  return result.join(' ... ');
}

function escapeRegExp(string) {
  return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
}

function boldString (fString, bString) {
 
// Escape the search term before using it in a regular expression
let escapedSearchTerm = escapeRegExp(bString);

// Normalize the search term to handle variations in character encoding
let normalizedSearchTerm = normalize(escapedSearchTerm);

// Function to normalize the search term to handle variations in character encoding
function normalize(term) {
  // Replace ASCII characters with their Unicode equivalents
  let normalizedTerm = term.replace(/[A-Za-z]/g, function (c) {
    return String.fromCharCode(c.charCodeAt(0) | 0x20);
  });
  // Normalize Unicode characters
  return normalizedTerm.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

// Normalize the text to handle variations in character encoding
let normalizedText = normalize(fString);

normalizedText = mySearchFunc(normalizedText, normalizedSearchTerm)
// Replace each occurrence of the search term with the highlighted version
let str = normalizedText.replace(new RegExp(`(${escapedSearchTerm}|${normalizedSearchTerm})`, 'gi'), function(match) {
  match = bString
  return `<span style="color:#FF8C00"><b>${match}</b></span>`;
});
return str;
 
  let regex = new RegExp(str, 'gi');

  return ; 
  const regex2 = new RegExp("(?:\\w+[\\s,;:'\".!?]+){0,3}" + bString + "(?:[\\s,;:'\".!?]+\\w+){0,3}", "gm");
    let m;
    let result = [];
    while ((m = regex2.exec(str)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex2.lastIndex) {
          regex2.lastIndex++;
        }

        if (m)
            //console.log(`Found: ${m}`);
            result.push(m[0]);
    }
    return result.join(' ... ');
    // var max = 500
    // //replace 15 with 500
    // return str.length > max ? str.substring(0, max) + '...' : str
  
  //return  
}


  return (
    <Box

      alignItems="center"
      mt = {12}
      sx={{
        width: "100%", display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      
      <Box px={5} pt={2} >
        {isLoading ? <CircularProgress color="text" /> :
          <>
            {ayats.length > 0 ?
              <>
                <Typography variant="default_body"><b>Total Number Occurence:</b> {ayats.length}</Typography><br /></> : <p>No Results Found!</p> }

            {

              ayats.map((ayat, count) => (
                <Box pt={2} style={{ cursor: "pointer" }} onClick={() =>
                (
                  setSelectedChapter({
                  id: ayat.chapterId,
                  title: ayat.chapterTitle,
                  arabicTitle: ayat.chapterArabicTitle,
                  totalAyats : ayat.totalAyats
                },
                setSelectedAyatNumber(ayat.ayatNumber),
                setViewType("Ayat")
                )
                  , navMenuItemClick("read"))}>
                  <Typography variant="default_body" ><b>Chapter: </b>{ayat.chapterTitle} <b>Ayat#:</b> {ayat.ayatNumber}</Typography><br />
                  <Typography variant="default_body" dangerouslySetInnerHTML={{ __html: boldString ( (ayat.context ? ayat.context : "")   , searchTerm) }} />
                  <Typography variant="default_body" dangerouslySetInnerHTML={{ __html: boldString (  ayat.translation + "" , searchTerm) }} />
                  <Typography  onClick={() => {
  handleOpenFootNote(ayat.reference)
  }}  variant="default_body" dangerouslySetInnerHTML={{ __html: boldString (  ayat.reference + "", searchTerm) }} />
                  <Divider />
                </Box>

              ))
            }
          </>
        }
      </Box>
    </Box>

  );
}
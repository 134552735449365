

import * as React from 'react';
import { Box, FormControl, TextareaAutosize, ThemeProvider, Typography, createMuiTheme, createTheme, makeStyles } from '@mui/material';
import {
  BtnBold,
  BtnBulletList,
  BtnItalic,
  BtnUnderline,
  Editor,
  EditorProvider,
  Toolbar
} from 'react-simple-wysiwyg';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { AutoStories, BookOnlineOutlined, TextSnippet } from '@mui/icons-material';
import { UserContext } from './context/UserContext';
import { Button } from 'react-bootstrap';
import { addNotes, getNotes } from './Api';
import AllNotes from './AllNotes';




export default function Notes({ selectedPhraseId,selectedPhrase,setCol, mode, bottomNavValue, handleBottomNavChange,log, handleIsUserLoginCheck, setSelectedChapter, setSelectedAyatNumber , setViewType}) {

  const { logout, loginUser, wait, loggedInCheck, registerUser } = React.useContext(UserContext);
  const isUserLoggedIn = loggedInCheck()
  console.log(selectedPhraseId)

  const [phraseId, setPhraseId] = React.useState(selectedPhraseId);
  const [html, setHtml] = React.useState("");
  const [phrase, setPhrase] = React.useState(selectedPhrase);
  const [saveText, setSaveText] = React.useState("Save");
  function onChange(e) {
    setHtml(e.target.value);
    localStorage.setItem('notes', e.target.value);
  }


  function closeNotes(e) {
    setCol(2)
    handleBottomNavChange(e,"Read")
  }

  function saveChanges(e) {
    setSaveText("Saving");
    // call api
    const formData = new FormData();
    //formData.append('label', tag)
    formData.append('notes', html)
    formData.append('ayat_id', phraseId)
    formData.append('user_id', localStorage.getItem("token"))

    addNotes(formData).then((result) => {
      setSaveText("Save");
    }).catch(console.error.bind(console))
  }

  React.useEffect(() => {
    setPhraseId(selectedPhraseId)
    setPhrase(selectedPhrase)
  }, [selectedPhraseId,selectedPhrase]);

  React.useEffect(() => {
    setHtml("Loading...")
    const formData = new FormData();
    formData.append('ayat_id', phraseId)
    formData.append('user_id', localStorage.getItem("token"))

    getNotes(formData).then((result) => {
      if (result && result.notes !== undefined) {
        setHtml(result.notes);
        setPhrase(result.arabicText)
      }
      else {
        setHtml("");
      }


    }).catch(console.error.bind(console))

  }, [phraseId, bottomNavValue]);

  const [value, setValue] = React.useState("Notes");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useLayoutEffect(() => {
    if(bottomNavValue != "Read")
    {
      setValue(bottomNavValue);
    }

  }, [bottomNavValue]);

  return (

    <Box sx={{ marginTop: -1 }}>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
     
          <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
            <TabList
              onChange={handleChange}
              aria-label=""
              sx={{ backgroundColor: '#f9c005' }}
            >
              <Tab  value="Notes" label="Notes" style={{ fontSize: "14px", textTransform: 'capitalize', fontWeight: 'bold' }} />
              <Tab  value="AllNotes" label="AllNotes" style={{ fontSize: "14px", textTransform: 'capitalize', fontWeight: 'bold' }} />
            </TabList>
          </Box>
          <TabPanel value="AllNotes" ><AllNotes setPhraseId ={setPhraseId} setValue={setValue} setSelectedChapter={setSelectedChapter} setSelectedAyatNumber={setSelectedAyatNumber} setViewType={setViewType}/></TabPanel>
          <TabPanel value="Notes" style={{ padding: 0, }}>
            
            <EditorProvider>
          
              {isUserLoggedIn ?
                <Editor value={html} onChange={onChange} containerProps={{ style: { border: 0, height: "80vh", resize: 'vertical', color: mode == "dark" ? '#fff' : '#000' } }}>
                  <Toolbar style={{ height: 50, backgroundColor: mode == "dark" ? '#000' : '#F9F8F0', border: 0 }}>
                    <BtnBold style={{ color: mode == "dark" ? '#fff' : '' }} />
                    <BtnItalic style={{ color: mode == "dark" ? '#fff' : '' }} />
                    <BtnBulletList style={{ color: mode == "dark" ? '#fff' : '' }} />
                    <BtnUnderline style={{ color: mode == "dark" ? '#fff' : '' }} />
                    <div style={{ marginLeft: 'auto' }}></div>
                    <Button   variant="contained" size="small" style={{ color: mode == "dark" ? '#fff' : '' }} onClick={saveChanges}>{saveText}</Button>
                    <Button   variant="contained" size="small" style={{ color: mode == "dark" ? '#fff' : '' }} onClick={closeNotes}>Close</Button>
                  </Toolbar>
                {
                  phrase ?  <Typography variant="text"  dir="rtl" style={{ overflowWrap: 'break-word', padding:1 }} dangerouslySetInnerHTML={{ __html:  (phrase ? phrase : "Please select a phrase") }} /> : 
                  <Typography variant="text"   style={{ overflowWrap: 'break-word', padding:1 }} dangerouslySetInnerHTML={{ __html:  (phrase ? phrase : "Please select a phrase") }} />
                }
                 
                  <hr />
                </Editor> : <SignInButtonView handleIsUserLoginCheck={handleIsUserLoginCheck} />}
            </EditorProvider>
          </TabPanel>
        </TabContext>
      </Box>

    </Box>

  );
}



function SignInButtonView({ handleIsUserLoginCheck }) {

  return (

    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="50vh"
    >
      <Button variant="contained" color="primary" onClick={handleIsUserLoginCheck}>Sign In to Continue</Button>
    </Box>
  )
}
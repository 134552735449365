import * as React from 'react';
import logo from './assets/images/logo.png'
import lang1 from './assets/images/lang-1.png'
import lang2 from './assets/images/lang-2.png'
import { Box, Typography } from '@mui/material';


export function Footer() {

    return (
        <Box className="footer" sx = {{backgroundColor : "bottomNav.bg"}}>
            <div className="container d-flex">
                <div className="menu">
                    <ul>
                        <li><a href="#"><Typography variant = "default_body">Read the Quran</Typography></a></li>
                        <li><a href="#"><Typography variant = "default_body">Audio</Typography></a></li>
                        <li><a href="#"><Typography variant = "default_body">Resources</Typography></a></li>
                        <li><a href="#"><Typography variant = "default_body">Notes</Typography></a></li>
                        <li><a href="#"><Typography variant = "default_body">Bookmarks</Typography></a></li>
                        <li><a href="#"><Typography variant = "default_body">Login</Typography></a></li>
                    </ul>
                </div>
                <div className="copyright"><Typography variant = "default_body">© 2022 Al-Maarif. All rights reserved</Typography></div>
            </div>
        </Box>
    )
}
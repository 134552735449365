
import * as React from 'react';
import { Box, CircularProgress, Divider, FormControl, Grid, TextareaAutosize, ThemeProvider, Typography, createMuiTheme, createTheme, makeStyles } from '@mui/material';
import {
  BtnBold,
  BtnBulletList,
  BtnItalic,
  BtnUnderline,
  Editor,
  EditorProvider,
  Toolbar
} from 'react-simple-wysiwyg';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { ArrowBack, AutoStories, BookOnlineOutlined, TextSnippet } from '@mui/icons-material';
import { getTags } from './Api';
import bRed from "./assets/images/popupicons/bookmark-tooltip-circle-red.svg";
import bBlue from "./assets/images/popupicons/bookmark-tooltip-circle-blue.svg";
import bGold from "./assets/images/popupicons/bookmark-tooltip-circle-yellow.svg";
import { useNavigate } from 'react-router-dom';




export default function Tag({ handleTags,label,setSelectedChapter ,setSelectedAyatNumber, navMenuItemClick,setViewType}) {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = React.useState(false);
    const [tags, setTags] = React.useState([]);
    console.log(label);
    // network call
    React.useEffect(() => {

        setIsLoading(true);
        const formData = new FormData();
            formData.append('label', label)
            formData.append('user_id', localStorage.getItem("token"))
    
      getTags(formData).then((result) => {
        setTags(result.tags);
        setIsLoading(false);
      }).catch(console.error.bind(console))

  }, [label]);


  return (
<>


    <Box

   
    mt = {12}
    sx={{
      width: "100%", display: 'flex',

      flexDirection: 'column'
    }}
  >
 <Typography   variant="default_body" onClick={() => navigate(-1)} p={2} style={{cursor:'pointer'}}><ArrowBack /> Back To Quran</Typography>
 <Grid item xs={12} sm={12} md={12} align="right" style={{paddingRight:20}}>
                    <img src={bRed}  width={20} height={10} onClick={() => handleTags("RED")} style={{cursor:"pointer"}}/>
                    <img src={bBlue} width={20}  height={10} onClick={() => handleTags("BLUE")} style={{cursor:"pointer"}}/>
                    <img src={bGold} width={20}  height={10} onClick={() => handleTags("GOLD")} style={{cursor:"pointer"}}/>
                 </Grid>
    <Box px={5} pt={2} >
      {isLoading ? <CircularProgress color="text" /> :
        <>

          { Array.isArray(tags) && tags.map((ayat, count) => (
                      <Box pt={2} style={{ cursor: "pointer" }} onClick={() =>
                      (
                        setSelectedChapter({
                        id: ayat.chapterId,
                        title: ayat.chapterTitle,
                        arabicTitle: ayat.chapterArabicTitle,
                        totalAyats : ayat.totalAyats
                      },
                      setSelectedAyatNumber(ayat.ayatNumber),
                      setViewType("Ayat")
              )
                , navMenuItemClick("read"))}>
                <Typography variant="default_body" ><b>Chapter: </b>{ayat.chapterTitle} <b>Ayat#:</b> {ayat.ayatNumber}</Typography><br />
                <Typography variant="default_body" dangerouslySetInnerHTML={{ __html: ayat.translation }} />

                <Divider />
              </Box>

            ))
          }
        </>
      }
    </Box>
  </Box>
  </>
  );
}
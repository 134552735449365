import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import logo from "../assets/logo.png";
import { UserContext } from '../context/UserContext';
import { useContext } from 'react';
import { DialogTitle, IconButton, Toolbar } from '@mui/material';
import { Close } from '@mui/icons-material';

export default function SignInDialog({ open, handleClose }) {
  const { loginUser, wait, loggedInCheck, registerUser } = useContext(UserContext);
  const [login, setLogin] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setError("")
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmitSignUp = async (event) => {
    event.preventDefault();

    // Validate each field individually
    const isEmailValid = validateEmail(formData.email);
    const isFirstNameValid = formData.firstname !== '';
    const isLastNameValid = formData.lastname !== '';
    const isPasswordValid = formData.password !== '';

    if (isEmailValid && isFirstNameValid && isLastNameValid && isPasswordValid) {
      // Continue with form submission logic
      var result = await registerUser(formData);
     if(result.status == "error")
      {
        setError(result.message)
      }
      else
      {
        setLogin(true)
      }
      console.log(result);
    } else {
      // Handle validation errors, show messages, etc.
      console.log("Validation failed");
    }
  };

  const handleSubmitLogin = async (event) => {
    event.preventDefault();

    // Validate each field individually
    const isEmailValid = validateEmail(formData.email);
    const isPasswordValid = formData.password !== '';

    if (isEmailValid && isPasswordValid) {
      // Continue with form submission logic
      var result = await loginUser(formData);
      if(result.status == "error")
      {
        setError(result.message)
      }
      else
      {
        localStorage.setItem("token", result.token);
        handleClose()
      }
      console.log(result);
    } else {
      // Handle validation errors, show messages, etc.
      console.log("Validation failed");
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
           
          
          <img src={logo} alt="logo" />
          <Typography component="h1" variant="h5">
            {!login ? "Sign up" : "Sign In"}
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={login ? handleSubmitLogin : handleSubmitSignUp}
            sx={{ mt: 3 }}
          >
             <Typography component="body" variant="default_body_error">
                 {error}
            </Typography>
            <br />
            <Grid container spacing={2}>
              {!login && (
                <>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="given-name"
                      name="firstname"
                      required
                      fullWidth
                      id="firstname"
                      label="First Name"
                      autoFocus
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      id="lastname"
                      label="Last Name"
                      name="lastname"
                      autoComplete="family-name"
                      onChange={handleChange}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  error={!validateEmail(formData.email)}
                  helperText={!validateEmail(formData.email) ? 'Invalid email format' : ''}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            {!login ? (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign Up
              </Button>
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
            )}
            <Grid container justifyContent="flex-end">
              <Grid item>
                {!login ? (
                  <Link
                    href="#"
                    variant="body2"
                    onClick={(event) => {
                      setLogin(true);
                    }}
                  >
                    Already have an account? Sign in
                  </Link>
                ) : (
                  <Link
                    href="#"
                    variant="body2"
                    onClick={(event) => {
                      setLogin(false);
                    }}
                  >
                    Don't have an account? Sign up
                  </Link>
                )}
              </Grid>
              <Grid item sx={{ height: 50 }}></Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Dialog>
  );
}


import * as React from 'react';
import { Box, CircularProgress, Divider, Typography } from '@mui/material';
import { getAllNotes } from './Api';



export default function AllNotes({setPhraseId,setValue, setSelectedChapter, setSelectedAyatNumber,setViewType}) {
  const [isLoading, setIsLoading] = React.useState(false);

  const [notes, setNotes] = React.useState([]);

  // network call
  React.useEffect(() => {

    setIsLoading(true);
    const formData = new FormData();
    formData.append('user_id', localStorage.getItem("token"))

    getAllNotes(formData).then((result) => {
      setNotes(result);
      setIsLoading(false);
    }).catch(console.error.bind(console))

  }, []);

  const truncateWithEllipsis = (htmlString, maxLength) => {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    const textContent = doc.body.textContent || "";
    return textContent.length > maxLength ? `${textContent.slice(0, maxLength)}...` : textContent;
  };
  
  return (
    <>
      <Box
        sx={{
          width: "100%", display: 'flex',
          flexDirection: 'column'
        }}
      >

        <Box   >

          {isLoading ? <CircularProgress color="text" /> :
            <>
              {Array.isArray(notes) && notes.map((note, count) => (
             <Box pt={2} onClick={() => {
              setViewType("Ayat");
              setTimeout(() => {
                  setPhraseId(note["ayatId"]);
                  setValue("Notes");
                  setSelectedChapter({
                      id: note.chapterId,
                      title: note.chapterTitle,
                      arabicTitle: note.chapterArabicTitle,
                      totalAyats: note.totalAyats
                  });
                  setSelectedAyatNumber(note.ayatNumber);
                  
              }, 100);
          }} style={{cursor: 'pointer' }}>
                  <Typography variant="arabic"  dir="rtl" style={{ overflowWrap: 'break-word' }} dangerouslySetInnerHTML={{ __html: note["arabicText"] }} />
                  <Typography variant="default_body" style={{ overflowWrap: 'break-word' }} dangerouslySetInnerHTML={{ __html: note["translation"] }} />
                   <Typography variant="default_body"><strong>NOTES:</strong> </Typography>
                  <Typography variant="default_body"  style={{ overflowWrap: 'break-word' }} dangerouslySetInnerHTML={{ __html: truncateWithEllipsis(note["notes"],100) }} />
                  <Divider />
                </Box>

              ))
              }
            </>
          }
        </Box>
      </Box>
    </>
  );
}
import { useContext, useState } from 'react'


import { Avatar, Box, Button, CssBaseline, FormControl, IconButton, Input, InputAdornment, InputLabel, Paper, TextField } from '@mui/material';
import { PeopleAlt, VisibilityOffTwoTone, VisibilityTwoTone } from '@mui/icons-material';
import logo from "./assets/logo.png";

const Register = () => {

  // const {registerUser, wait} = useContext(UserContext);
  const [hidePassword, setHidePassword] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: ''
  });

  const onChangeInput = (e) => {
    setFormData({
        ...formData,
        [e.target.name]:e.target.value
    })
  }



  const submitForm = async (e) => {
    e.preventDefault();
    //alert(formData.email + formData.password +formData.confirmPassword);
    if (!Object.values(formData).every(val => val.trim() !== '')) {
      setSuccessMsg(false);
      setErrMsg('Please Fill in all Required Fields!');
      return;
    }
  }




  return (

    <Box>
      <CssBaseline />
      <Box className={"main"} sx={{ display: 'flex', flexDirection: 'column', height: "100vh", width: "100%" }}
        justifyContent="center"
        alignItems="center">

        <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: 400, p: 5 }} justifyContent="center" alignItems="center">
          <img src={logo} />
          <h2>Register</h2>
          <form

            onSubmit={submitForm}
          >
            <FormControl required fullWidth margin="normal">
              <TextField
                name="email"
                color="text"
                label="Email"
                id="outlined-email-small"
                size="small"
                onChange = {onChangeInput}
              />
            </FormControl>
            <FormControl required fullWidth margin="normal">
              <TextField
                name="password"
                label="Password"
                id="outlined-password-small"
                size="small"
                color="text"
                onChange = {onChangeInput}
              />
            </FormControl>
            <FormControl required fullWidth margin="normal">
              <TextField
                name="confirmPassword"
                color="text"
                label="Confirm Password"
                id="outlined-size-small"
                size="small"
                onChange = {onChangeInput}
              />
            </FormControl>
            <FormControl required fullWidth margin="normal">
              <Button type="submit" variant="contained" color="button" sx={{fontWeight:'bold'}}>Register</Button>
            </FormControl>
          </form>
        </Box>

      </Box>
    </Box>
  )
}

export default Register;
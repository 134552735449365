import { createTheme } from "@mui/material";

const createThemeOptions = (isDarkMode)=>({
 
  palette: {
    primary: {
      main: '#282828',
    },
    secondary: {
      main: isDarkMode == "dark" ? '#fff' : isDarkMode == "light" ?  '#282828' : "#282828",
    },
    body: {
      main: isDarkMode == "dark" ? '#282828' : isDarkMode == "light" ?  '#fff' : "#f3eed9",
    },
    text:{
      main:isDarkMode == "dark" ? '#fff' : '#000',
    },
    slider:{
      main:isDarkMode == "dark" ? '#fff' : '#282828',
    },

    button:{
      main: '#FFC10E'
    },
    
    bottomNav:{
      main:isDarkMode == "dark" ? '#fff' : '#000',
      light:isDarkMode == "dark" ? '#fff' : '#000',
       bg: isDarkMode == "dark" ? '#0F0F0F' : isDarkMode == "light" ?  '#fff' : "#f3eed9",
    },
  },
  
    typography: {
        appBarTitle: {
        lineHeight: 1.6,
        fontSize: 24,
        fontWeight: "bold",
        color:isDarkMode == "dark" ? '#fff' : '#000'
      },
      listItem: {

        fontSize: 16,
        fontWeight: 500,
        color:isDarkMode == "dark" ? '#fff' : '#000'
      },
      title: {
        lineHeight: 1.6,
        fontSize: 28,
        fontWeight: 800,
        color:isDarkMode == "dark" ? '#fff' : '#000'
      },
      titleArabic: {
        lineHeight: 1.6,
        fontSize: 28,
        fontWeight: 800,
        color:isDarkMode == "dark" ? '#fff' : '#000'
      },
      topic: {
        lineHeight: 1.6,
        fontSize: 27,
        fontWeight: 800,
        color:isDarkMode == "dark" ? '#fff' : '#000'
      },
      subTopic: {
        lineHeight: 1.6,
        fontSize: 22,
        fontWeight: 800,
        color:isDarkMode == "dark" ? '#fff' : '#000'
      },
      transiliteration: {
        lineHeight: 1.6,
        fontSize: 16,
        fontWeight: 800,
        color:isDarkMode == "dark" ? '#fff' : '#000'
      },
      translation: {
        lineHeight: 1.6,
        fontSize: 14,
        fontWeight: 500,
        color:isDarkMode == "dark" ? '#fff' : '#000'
      },
      arabic: {
        lineHeight: 1.6,
        fontSize: 18,
        fontWeight: 700,
        color:isDarkMode == "dark" ? '#fff' : '#000'
      },

      contextText: {
        lineHeight: 1.6,
        fontSize: 12,
        color:isDarkMode == "dark" ? '#fff' : '#000'
      },

      menuItem: {
        lineHeight: 1.6,
        fontSize: 18,
        fontWeight: 500,
        cursor: "pointer",
        color:isDarkMode == "dark" ? '#fff' : '#000'
      },

      default_body_error: {
        lineHeight: 1.6,
        fontSize: 14,
        fontWeight: 400,
        color: "#FF0000" 
      },

      default_body: {
        lineHeight: 1.6,
        fontSize: 14,
        fontWeight: 400,
        color:isDarkMode == "dark" ? '#fff' : '#000'
      },

      body_title1: {
        lineHeight: 1.6,
        fontSize: 16,
        fontWeight: 700,
        color:isDarkMode == "dark" ? '#fff' : '#202647'
      },

      home_body_title: {
        lineHeight: 1.6,
        fontSize: 24,
        fontWeight: 700,
        color:isDarkMode == "dark" ? '#fff' : '#202647'
      },
  
      body2_medium: {
        lineHeight: 1.6,
        fontSize: 18,
        fontWeight: 500,
        color:isDarkMode == "dark" ? '#fff' : '#000'
      },
      p1_italic: {
        lineHeight: 2.6,
        fontSize: 26,
        fontStyle: "italic",
        fontWeight: 800,
      },
      p1_bold: {
        lineHeight: 2.6,
        fontSize: 22,
        fontStyle: "bold",
        fontWeight: 700
      },
      p1_error: {
        fontStyle: "bold",
        fontWeight: 300,
        color: "red",
      },
      p2_highlighted: {
        fontStyle: "italic",
        fontWeight: 500,
        backgroundColor: "yellow",
      },
    },
  });

  export const darkTheme = createTheme(createThemeOptions("dark"));
  export const lightTheme = createTheme(createThemeOptions("light"));
  export const sepiaTheme = createTheme(createThemeOptions("sepia"));
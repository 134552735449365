import * as React from 'react';
import banner from './assets/images/banner.jpg'
import bannerIcon from './assets/images/banner-logo-icon.png'
import icon1 from './assets/images/icon-1.png'
import icon2 from './assets/images/icon-2.png'
import icon3 from './assets/images/icon-3.png'
import { Button, Fade } from 'react-bootstrap';
import { CircularProgress, LinearProgress, Typography } from '@mui/material';
import { Footer } from './Footer';
import { Box } from '@mui/system';

export function Main() {
    const [open, setOpen] = React.useState(false);
    setTimeout(function () {
        setOpen(true)
    }.bind(this), 500)
    return (
        <Box>
        {open != true ? <LinearProgress color="inherit" />:
        <Fade in={open} timeout={3000}>
        <Box>
            <div className="banner">
                <div className="full-img" ><img src={banner} alt="" /></div>
                <div className="container d-flex">
                    <div className="content">
                        <Box     display="flex" justifyContent="center"
                                alignItems="center">
                                     <Box   justifyContent="center"
                                alignItems="center" sx={{width:{xs:"20%", sm:"20%",md:"100%"}}}> <img src={bannerIcon} alt=""  /></Box>
                       
                        </Box>
                       
                      
                        <h1>Read the Quran Online</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elitsed do eiusmodest </p>
                        <Button variant='contained' style={{color:"#FFF"}} >Read More</Button>
                     
                    </div>
                </div>
            </div>
        
            <div className="section home-w1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <img src={icon1} alt="" />
                            <div className="pt-4 pb-4 coleql_height">
                                <Typography variant = "home_body_title">About us</Typography><br />
                                <Typography variant = "default_body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                    labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                    laboris nisi ut aliquip ex ea commodo consequat. </Typography>
                            </div>
                            <a href="#" className="btn btn-primary">Learn More</a>
                        </div>
                        <div className="col-md-4">
                            <img src={icon2} alt="" />
                            <div className="pt-4 pb-4 coleql_height">
                            <Typography variant = "home_body_title">Find a Quran</Typography><br />
                                <Typography variant = "default_body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                    labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                    laboris nisi ut aliquip ex ea commodo consequat. </Typography>
                            </div>
                            <a href="#" className="btn btn-primary">Learn More</a>
                        </div>
                        <div className="col-md-4">
                            <img src={icon3} alt="" />
                            <div className="pt-4 pb-4 coleql_height">
                            <Typography variant = "home_body_title">Download the Quran App</Typography><br />
                                <Typography variant = "default_body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                    labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                    laboris nisi ut aliquip ex ea commodo consequat.</Typography>
                             
                            </div>
                            <a href="#" className="btn btn-primary">Learn More</a>
                        </div>
                    </div>
                </div>
            </div>


            <div className="section newsletter">
                <div className="container">
                    <div className="content">
                        <h4>Connect with us!</h4>
                        <form action="#" method="post">
                            <input type="text" className="form-control" placeholder="Enter your email" />
                            <input type="submit" className="btn btn-primary" value="Subscribe" />
                        </form>
                        <ul className="social d-flex justify-content-center">
                            <li><a href="#" target="_blank"><i className="fa-brands fa-facebook-f"></i></a></li>
                            <li><a href="#" target="_blank"><i className="fa-brands fa-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i className="fa-brands fa-instagram"></i></a></li>
                            <li><a href="#" target="_blank"><i className="fa-brands fa-vimeo-v"></i></a></li>
                            <li><a href="#" target="_blank"><i className="fa-brands fa-youtube"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <Footer />
        </Box>
        
        </Fade>

          }
          
          
          </Box>
  
    )
}
import { Box, CircularProgress, Container, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { indexes, searchByWord } from "./Api";
import { Navigate } from "react-router-dom";

export default function Glossary({ setSelectedChapter, setSelectedAyatNumber, navMenuItemClick, handleSearch2 }) {
    const [isLoading, setIsLoading] = useState(false);
    const [indexesData, setIndexesData] = useState([]);




    useEffect(() => {
        setIsLoading(true);
        indexes().then((result) => {
            var indexes = result.indexes
            indexes =  indexes.sort((a, b) => replaceAndLower(a.indexName).localeCompare(replaceAndLower(b.indexName), 'en', { sensitivity: 'base' }));
            setIndexesData(indexes)
            setIsLoading(false);
        }).catch(console.error.bind(console))
    }, []);


    const handleClickIndex = (e) => {
        console.log(e)
        //handleSearch2(e)
    };

    function replaceAndLower(str) {
        return str
          .toLowerCase()
          .replace(/ā/g, 'a')
          .replace(/ḍ/g, 'd')
          .replace(/ḥ/g, 'h')
          .replace(/ī/g, 'i')
          .replace(/ṣ/g, 's')
          .replace(/ṭ/g, 't')
          .replace(/ū/g, 'u')
          .replace(/ẓ/g, 'z')
          .replace(/'/g, '')
          .replace(/ʿ/g, '')
          .replace(/’/g, ''); 
      }
      

    return (
        <Box
            alignItems="center"
            mt={12}
            sx={{
                width: "100%", display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}
        >
            <Box px={5} pt={4} >
                {isLoading ? <CircularProgress color="text" /> :
                    <>
                        <Grid container spacing={2}>
                            { indexesData.map((item, count) => (
                                    <Grid item xs={4} style={{ fontSize: 18 }} onClick={(event) => { handleClickIndex(item.indexName) }}>
                                       <Typography variant='default_body'>{item.indexName}</Typography>

                                        {item.refrences.map((ref2, count) => (
                                            <>
                                                <span style={{ fontSize: 12, cursor: "pointer", color: "blue" }} onClick={() =>
                                                (
                                                    setSelectedChapter({
                                                        id: ref2.chapterId,
                                                        title: ref2.chapterTitle,
                                                        arabicTitle: ref2.chapterArabicTitle,
                                                        totalAyats: ref2.totalAyats
                                                    },
                                                        setSelectedAyatNumber(ref2.ayatNumber)
                                                    )
                                                    , navMenuItemClick("read"))}> 
                                                    [{ref2.number}:{ref2.ayatNumber}]
                                                 </span>
                                            </>
                                        ))}
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </>
                }
            </Box>
        </Box>

    );
}

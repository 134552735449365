import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/style.css';
import './assets/css/all.css';
import './assets/css/bootstrap.css';
import './assets/css/responsive.css';
import './assets/css/stellarnav.css';
import './App.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { theme } from "./ALMTheme";
import { ThemeProvider } from "@mui/material/styles";

import { Footer } from './Footer';
import { Main } from './Main';
import { Toolbar } from '@mui/material';
import $ from 'jquery';
import Entry from './Entry';

const root = ReactDOM.createRoot(document.getElementById('root'));
function createJS($count) {

   $(function () {
       $('.item' + $count).hover(function () {
           $(this).css('color', '#FF8C00');
           $('.itemT' + $count).css('color', '#FF8C00');

       },
           function () {
               $(this).css('color', '#000');
               $('.itemT' + $count).css('color', '#000');

           });
   });
   $(function () {
       $('.itemT' + $count).hover(function () {
           $(this).css('color', '#FF8C00');
           $('.item' + $count).css('color', '#FF8C00');
           $('.itemT' + $count).css('color', '#FF8C00');

       },
           function () {
               $(this).css('color', '#000');
               $('.item' + $count).css('color', '#000');
               $('.itemT' + $count).css('color', '#000');

           });

   });

   $(function () {
      
           $('.refGroup').click(function(){
               alert("The paragraph was clicked.");
             });

   });
   

}



root.render(

   <React.StrictMode>


         
         <Entry />
         

      
   </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

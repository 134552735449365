import { createContext, useState, useEffect } from 'react'
import axios from 'axios'

export const UserContext = createContext();

const BASE_URL = "https://almquran.ecnet.dev/website/"
const config = {
    headers: {
        'content-type': 'application/x-www-form-urlencoded'
    }
}

export const UserContextProvider = ({ children }) => {

    const [theUser, setUser] = useState(null);
    const [wait, setWait] = useState(false);

    const registerUser = async ($params) => {

        try {
            const response = await axios.post(BASE_URL + 'register', $params, config)
            console.log('response  ', response)
            return response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const { status, data } = error.response;
                console.log('error  ', error)
                if (status === 400 && data && data.messages) {
                    // Concatenate error messages into a single string
                    const errorMessages = Object.keys(data.messages).map(
                        (field) => `${field}: ${data.messages[field]}`
                    );

                    const errorMessageString = errorMessages.join('\n');
                    console.error('Validation Error:', errorMessageString);
                    return { status: "error", message: errorMessageString };
                    // You can use errorMessageString as needed (e.g., display it to the user)
                }
            }
            return { status: "error", message: "Please try again" };
        }
    }

    const loginUser = async ($params) => {
        try {
            const response = await axios.post(BASE_URL + 'login', $params, config);
            console.log('response', response);

            // Assuming the server response contains a 'status' field
            if (response.data.status === 'success') {
                // Successful login
                return response.data;
            } else {
                // Server returned an error status
                console.log('Login failed:', response.data.message);
                return { status: "error", message: response.data.message };
            }
        } catch (error) {
            // Network error or other unexpected errors
            console.error('Login error:', error);

            // Check if it's an HTTP error response
            if (error.response) {
                // The request was made, but the server responded with a status code
                // For example, handle unauthorized access
                if (error.response.status === 401) {
                    console.log('Unauthorized access:', error.response.data.message);
                    return { status: "error", message: "Invalid email or password" };
                }
                // Handle other HTTP error status codes as needed
                // ...
            }

            // Handle non-HTTP errors
            return { status: "error", message: "Unexpected error occurred" };
        }
    };


    const loggedInCheck = () => {
        // Get the token from local storage
        const token = localStorage.getItem('token');
        console.log(token)
        // Check if the token is present and consider it valid
        return !!token;
    };

    // useEffect(() => {
    //     async function asyncCall() {
    //         await loggedInCheck();
    //     }
    //     asyncCall();
    // }, []);

    const logout = () => {
        localStorage.removeItem('token');
        setUser(null);
        console.log("logout")
    }

    return (
        <UserContext.Provider value={{ registerUser, loginUser, wait, user: theUser, loggedInCheck, logout }}>
            {children}
        </UserContext.Provider>
    );

}

export default UserContextProvider;